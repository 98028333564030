<template>

  <el-row :gutter='10'>
    <div class="dialogCard">
      <div class="subBox">
        <div class="title">自然流量</div>
        <!-- <div class="subTitle">更新时间：2024/05/11 09:40</div> -->
      </div>

      <el-row style="width:100%;">
        <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" v-for="(item, index) in data" :key="index" v-if="!noData">
          <el-row style="width:100%;">
            <el-col :lg="4" :md="12" :sm="12" :xl="4" :xs="24" class="ul" v-for="(sitem, sindex) in item" :key="sindex">
              <p class='smTitle' v-if="sitem.showTitle">{{sitem.name}}</p>
              <vab-count v-if="!sitem.notshowCount" :decimals="sitem.countConfig.decimals" :duration="sitem.countConfig.duration" :end-val="sitem.countConfig.endVal" :prefix="sitem.countConfig.prefix" :separator="sitem.countConfig.separator" :start-val="sitem.countConfig.startVal"
                style="font-size:26px;color:#1D2129;font-weight:bold;" />
              <span style="font-size:16px;color:#4e5969">{{sitem.countConfig.suffix}}</span>
            </el-col>
          </el-row>
        </el-col>

        <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" v-else style="display: flex; justify-content: center;">
          <noData></noData>
        </el-col>

      </el-row>
    </div>
  </el-row>

</template>

<script>
import { defineComponent, reactive, toRefs, watch } from 'vue'
import _ from 'lodash'
import VabChart from '@/extra/VabChart'
import VabCount from '@/extra/VabCount'
import noData from '../noData.vue'

export default defineComponent({
  components: {
    VabChart,
    VabCount,
    noData,
  },
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  setup(props) {
    watch(
      () => props.data,
      (newVal, oldVal) => {
        let dataArr = []
        let realData = [
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '',
              separator: ',',
              duration: 0,
            },
            name: '日期',
            colCount: 8,
            notshowCount: true,
            showTitle: false,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '人',
              separator: ',',
              duration: 3000,
            },
            name: '当日注册',
            colCount: 8,
            showTitle: false,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '人',
              separator: ',',
              duration: 3000,
            },
            name: '累计注册',
            colCount: 8,
            showTitle: false,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '人',
              separator: ',',
              duration: 3000,
            },
            name: '当日签到',
            colCount: 8,
            showTitle: false,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '人次',
              separator: ',',
              duration: 3000,
            },
            name: '累计签到',
            colCount: 8,
            showTitle: false,
          },
        ]
        for (const value of Object.values(newVal)) {
          let nrealData = _.cloneDeep(realData)
          nrealData[0].countConfig.suffix = value.date
          nrealData[1].countConfig.endVal = value.signUpNum
          nrealData[2].countConfig.endVal = value.totalSignUpNum
          nrealData[3].countConfig.endVal = value.signInNum
          nrealData[4].countConfig.endVal = value.totalSignInNum
          dataArr.push(nrealData)
        }
        if (dataArr.length == 0) {
          state.noData = true
        } else {
          state.noData = false
          for (let i of dataArr[0]) {
            i.showTitle = true
          }
        }
        state.data = dataArr
        setTimeout(() => {
          state.loading = false
        }, 100)
      }
    )
    const state = reactive({
      loading: true,
      data: [],
      noData: false,
    })
    return {
      ...toRefs(state),
    }
  },
})
</script>


<style lang="scss" scoped>
.dialogCard {
  width: 100%;
  min-height: 174px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
  .subBox {
    .title {
      font-weight: 500;
      font-size: 16px;
      color: #1d2129;
      margin-bottom: 4px;
    }
    .subTitle {
      font-size: 12px;
      color: #4e5969;
    }
  }
  .ul1 {
    min-height: 100px;
  }
  .ul {
    margin-top: 15px;
    text-align: center;
    .smTitle {
      font-size: 16px;
      color: #1d2129;
    }
  }
}
</style>